<template>
    <div class="animated fadeIn">
        <b-card class="data-provider-preview" v-if="customQuery">
            <h1>{{ customQuery.name }}</h1>
            <div class="data-provider-preview__body">
                <div class="data-provider-preview__body-left">
                    <b-carousel v-model="slide" :interval="8000" indicators>
                        <b-carousel-slide
                            v-for="img in customQuery.preview.images"
                            :img-src="img"
                            :key="img"
                        ></b-carousel-slide>
                    </b-carousel>

                    <div>
                        <preview-connectors v-if="fullRequiredConnectors" :connectors="fullRequiredConnectors" required>
                            <template #header>Required connectors in this report:</template>
                        </preview-connectors>

                        <preview-connectors v-if="fullOptionalConnectors" :connectors="fullOptionalConnectors">
                            <template #header>Possible to connect with:</template>
                        </preview-connectors>
                    </div>
                </div>
                <div class="data-provider-preview__body-right">
                    <div v-html="description" class="data-provider-preview__description" />
                    <div class="data-provider-preview__buttons-all">
                        <div class="data-provider-preview__buttons">
                            <a
                                v-for="button in customQuery.preview.buttons"
                                :href="button.url"
                                target="_BLANK"
                                :key="button.label"
                                class="data-provider-preview__button"
                            >
                                <button>
                                    <img :src="button.image" />
                                    <span>{{ button.label }}</span>
                                    <i class="fa fa-external-link" />
                                </button>
                            </a>
                        </div>
                        <component
                            :is="
                                notProvidedRequired || (notProvidedOptional && !optionalOptOut)
                                    ? 'div'
                                    : 'project-router-link'
                            "
                            :to="`/reports/dataproviders/create/${customQuery.id}`"
                            class="data-provider-preview__create"
                            @click="onCreateButtonClick"
                        >
                            <button>
                                Create your own report
                            </button>
                        </component>
                    </div>
                </div>
            </div>
        </b-card>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
        <RequiredModal v-model="showRequiredModal" :connectors="fullRequiredConnectorsFiltered" />
        <OptionalModal v-model="showOptionalModal" :connectors="fullOptionalConnectorsFiltered" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

import Loading from '@/components/loading.vue'
import PreviewConnectors from './Connectors.vue'

import {fetchAll} from '@/shared/fetchAll.js'
import {collectTypes} from '@/shared/collectTypes'
import {processTypes} from '@/shared/processTypes'
import {reportTypes} from '@/shared/reportTypes'

const MarkdownIt = require('markdown-it')

export default {
    components: {
        Loading,
        PreviewConnectors,
        RequiredModal: () => import('./RequiredModal.vue'),
        OptionalModal: () => import('./OptionalModal.vue'),
    },
    data() {
        return {
            customQuery: null,
            md: new MarkdownIt(),
            slide: 0,
            showRequiredModal: false,
            showOptionalModal: false,
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            collect: 'collect/all',
            process: 'process/all',
            report: 'report/all',
        }),
        description() {
            return this.customQuery && this.customQuery.preview && this.customQuery.preview.description
                ? this.md.render(this.customQuery.preview.description, {sanitize: true})
                : ''
        },
        types() {
            return [...collectTypes, ...processTypes, ...reportTypes]
        },
        fullRequiredConnectors() {
            return this.customQuery && this.customQuery.preview && this.customQuery.preview.requiredConnectors
                ? this.customQuery.preview.requiredConnectors.map(connector => {
                      const type = this.types.find(el => `${el.type} ${el.subtype}` === connector.type)
                      return {
                          ...connector,
                          id: `preview-connector-${connector.type.replace(/\s+/g, '-').toLowerCase()}`,
                          isProvided: this.isProvided(connector),
                          displayType: type.subtype,
                          link: type.link,
                      }
                  })
                : []
        },
        fullOptionalConnectors() {
            return this.customQuery && this.customQuery.preview && this.customQuery.preview.optionalConnectors
                ? this.customQuery.preview.optionalConnectors.map(connector => {
                      const type = this.types.find(el => `${el.type} ${el.subtype}` === connector.type)
                      return {
                          ...connector,
                          id: `preview-connector-${connector.type.replace(/\s+/g, '-').toLowerCase()}`,
                          isProvided: this.isProvided(connector),
                          displayType: type.subtype,
                          link: type.link,
                      }
                  })
                : []
        },
        notProvidedRequired() {
            return this.fullRequiredConnectors.filter(el => !el.isProvided).length > 0
        },
        notProvidedOptional() {
            return this.fullOptionalConnectors.filter(el => !el.isProvided).length > 0
        },
        optionalOptOut() {
            return localStorage.getItem(`optional-connectors-opt-out-${this.$auth.user().id}`) === 'true'
        },
        fullRequiredConnectorsFiltered() {
            return this.fullRequiredConnectors.filter(el => !el.isProvided)
        },
        fullOptionalConnectorsFiltered() {
            return this.fullOptionalConnectors.filter(el => !el.isProvided)
        },
    },
    async mounted() {
        try {
            this.$store.commit('loading/PROCESSING', `Preparing the preview...`)

            await Promise.all([this.fetchCustomQuery(), fetchAll(this.$store, this.activeProject.id)])
            if (this.$route.query.continueFlow) {
                this.onCreateButtonClick()
            }

            this.$store.commit('loading/PROCESSED')
        } catch (e) {
            this.$errorHandler.report(e, 'Could not prepare preview')
            this.$store.commit('loading/PROCESSED')
            this.$projectRouter.push('/reports/dataproviders/list?createError=true')
        }
    },
    methods: {
        async fetchCustomQuery() {
            const {id} = this.$route.params
            const {
                data: {data},
            } = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/customQuery/${id}`)

            this.customQuery = data
        },

        isProvided(connector) {
            return (
                [...this.collect, ...this.process, ...this.report].findIndex(
                    el => `${el.type} ${el.subtype}` === connector.type
                ) > -1
            )
        },

        onCreateButtonClick() {
            if (
                (!this.customQuery.preview.requiredConnectorsCondition ||
                    this.customQuery.preview.requiredConnectorsCondition !== 'ANY') &&
                this.notProvidedRequired
            ) {
                this.showRequiredModal = true
                return
            }

            if (
                this.customQuery.preview.requiredConnectorsCondition === 'ANY' &&
                this.fullRequiredConnectors.filter(el => !el.isProvided).length === this.fullRequiredConnectors.length
            ) {
                this.showRequiredModal = true
                return
            }

            if (this.notProvidedOptional && !this.optionalOptOut) {
                this.showOptionalModal = true
                return
            }

            this.$projectRouter.push(`/reports/dataproviders/create/${this.customQuery.id}`)
        },
    },
}
</script>

<style lang="scss">
.data-provider-preview {
    h1 {
        margin-bottom: 30px;
    }

    .data-provider-preview__body {
        display: flex;
        flex-flow: row nowrap;
        margin: 0 -25px;
        max-height: 50%;
        height: 50%;
        // max-height: calc(100vh - 300px);

        .data-provider-preview__body-left,
        .data-provider-preview__body-right {
            width: 50%;
            margin: 0 25px;
            flex: 1 1 auto;
        }

        .data-provider-preview__body-left {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            flex-grow: 1;

            .carousel {
                max-width: calc(min(100%, 800px));
                margin-bottom: 50px;
                border-radius: 5px;
                overflow: hidden;
                flex-grow: 1;
                height: 100%;
                height: calc(100vh - 600px);

                .carousel-inner,
                .carousel-item {
                    height: 100%;
                }

                img {
                    object-fit: contain;
                    max-width: 100%;
                    height: 100%;
                }

                .carousel-control-prev-icon {
                    background-image: url('~@/assets/img/carousel-left.svg');
                }
                .carousel-control-next-icon {
                    background-image: url('~@/assets/img/carousel-right.svg');
                }

                .carousel-indicators {
                    li {
                        background-color: #ffc32b;
                    }
                }
            }
        }

        .data-provider-preview__body-right {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;

            padding-right: 50px;

            .data-provider-preview__description {
                font-size: 1.1rem;
                padding-top: 30px;
                flex-grow: 1;
            }
        }
    }

    .data-provider-preview__buttons {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -5px;
    }
}

a.data-provider-preview__button {
    flex-grow: 1;
    margin: 0 5px 1rem;
    text-decoration: none;

    &:hover {
        text-decoration: none;

        button {
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
        }
    }

    button {
        width: 100%;
        font-weight: 600;
        font-size: 0.9rem;
        border-radius: 5px;
        padding: 0.375rem 0.95rem;
        border: 1px solid #a4b7c1;
        background-color: white;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        img {
            height: 32px;
        }

        span {
            padding: 0 5px;
        }
    }
}

.data-provider-preview__create {
    width: 100%;
    text-decoration: none;

    &:hover {
        text-decoration: none;

        button {
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
        }
    }

    button {
        width: 100%;
        height: 46px;
        font-weight: 600;
        font-size: 0.9rem;
        border-radius: 5px;
        padding: 0.375rem 0.95rem;
        border: 1px solid rgb(255, 195, 43);
        background-color: #ffdb80;

        &:disabled {
            color: black;
            background-color: #fff0ca;
            cursor: not-allowed;
        }
    }
}

.data-provider-preview__buttons-all {
    margin: 10px 0;
}
</style>
