export const processTypes = [
    {
        type: 'Data Export',
        subtype: 'Google Analytics 4 Cost',
        link: '/process/analytics4Cost/create',
        img: 'logotypes/analytics_36.svg',
    },
    {
        type: 'Custom Tasks',
        subtype: 'Withook',
        link: '/process/withooks/create',
        img: 'logotypes/withook_36.svg',
    },
    {
        type: 'Data Export',
        subtype: 'Save to storage',
        link: '/process/savetostorage/create',
        img: 'logotypes/storage_36.svg',
    },
]
