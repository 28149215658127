<template>
    <div v-if="loading" v-scroll-lock="loading" class="loading-page">
        <span id="bee-loader">
            <img src="../assets/img/honeybee.svg" class="loading-bee" />
            <p style="color: #333; font-size: 1rem">{{ text }}</p>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
        },
        text: {
            type: String,
        },
    },
}
</script>

<style scoped>
.loading-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-size: 30px;
    font-family: sans-serif;
    z-index: 10000000;
    font-weight: 600;
}

#bee-loader {
    width: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loading-bee {
    width: 3rem;
    -o-object-fit: contain;
    object-fit: contain;
    margin-bottom: 3rem;
    -webkit-animation: rot 0.6s infinite ease-out;
    animation: rot 0.6s infinite ease-out;
}

@-webkit-keyframes rot {
    from {
        -webkit-transform: rotate(0deg) translate(-2rem) rotate(0deg);
        transform: rotate(0deg) translate(-2rem) rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg) translate(-2rem) rotate(-360deg);
        transform: rotate(360deg) translate(-2rem) rotate(-360deg);
    }
}

@keyframes rot {
    from {
        -webkit-transform: rotate(0deg) translate(-2rem) rotate(0deg);
        transform: rotate(0deg) translate(-2rem) rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg) translate(-2rem) rotate(-360deg);
        transform: rotate(360deg) translate(-2rem) rotate(-360deg);
    }
}
</style>
