<template>
    <div v-if="connectors.length" class="data-provider-preview__connectors-block">
        <slot name="header"></slot>
        <div class="data-provider-preview__connectors-wrapper">
            <div class="data-provider-preview__connectors-icon" :id="connector.id" v-for="connector in connectors">
                <project-router-link :to="`${connector.link}?reportId=${$route.params.id}`">
                    <img
                        :src="connector.image"
                        :class="{'data-provider-preview__connectors-wrapper-icon--not-provided': !connector.isProvided}"
                    />
                    <b-tooltip :target="connector.id" triggers="hover" custom-class="data-provider-preview__tooltip">
                        {{ connector.displayType
                        }}<span v-if="required && !connector.isProvided"> is required. Click here to create it</span>
                    </b-tooltip>
                </project-router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        connectors: {
            type: Array,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss">
.data-provider-preview__connectors-block {
    font-size: 0.9rem;
    font-weight: 400;

    .data-provider-preview__connectors-wrapper {
        display: flex;
        flex-flow: row wrap;
        margin: 5px -5px;

        img {
            margin: 5px;
            height: 50px;
            object-fit: contain;

            &.data-provider-preview__connectors-wrapper-icon--not-provided {
                filter: grayscale(100%);
            }
        }
    }
}

.data-provider-preview__tooltip {
    z-index: 6000;
}
</style>
