export const collectTypes = [
    {
        type: 'User Behaviour',
        subtype: 'Google Analytics 4 BigQuery',
        link: '/collect/analytics4bq/create',
        img: 'logotypes/analytics_36.svg',
    },

    {
        type: 'User Behaviour',
        subtype: 'Search Console',
        link: '/collect/searchConsole/create',
        img: 'logotypes/search_console_36.svg',
    },
    {
        type: 'Ad Systems',
        subtype: 'Google Ads',
        link: '/collect/googleads/create',
        img: 'logotypes/google_ads_36.svg',
    },
    {
        type: 'Ad Systems',
        subtype: 'Facebook Ads',
        link: '/collect/facebook/create',
        img: 'logotypes/facebook_36.svg',
    },
    {
        type: 'Ad Systems',
        subtype: 'Criteo Ads',
        link: '/collect/criteo/create',
        img: 'logotypes/criteo_36.svg',
    },
    {
        type: 'Ad Systems',
        subtype: 'Tradedoubler',
        link: '/collect/tradedoubler/create',
        img: 'logotypes/tradedoubler_36.svg',
    },
    {
        type: 'Ad Systems',
        subtype: 'RTB House',
        link: '/collect/rtb/create',
        img: 'logotypes/rtbhouse_36.svg',
    },
    {
        type: 'Ad Systems',
        subtype: 'Optimise',
        link: '/collect/optimise/create',
        img: 'logotypes/optimise_36.svg',
    },
    {
        type: 'Ad Systems',
        subtype: 'Awin',
        link: '/collect/awin/create',
        img: 'logotypes/awin_36.svg',
    },
    {
        type: 'Ad Systems',
        subtype: 'Domodi & Allani',
        link: '/collect/domodi/create',
        img: 'logotypes/domodi_36.svg',
    },
    {
        type: 'Ad Systems',
        subtype: 'Convertiser',
        link: '/collect/convertiser/create',
        img: 'logotypes/convertiser.svg',
    },
    {
        type: 'Ad Systems',
        subtype: 'TikTok',
        link: '/collect/tiktok/create',
        img: 'logotypes/tiktok.svg',
    },
    {
        type: 'Ad Systems',
        subtype: 'Bing Ads',
        link: '/collect/bing/create',
        img: 'logotypes/bing.svg',
    },
    {
        type: 'Ad Systems',
        subtype: 'Ceneo',
        link: '/collect/ceneo/create',
        img: 'logotypes/ceneo_36.svg',
    },
    {
        type: 'Ecommerce',
        subtype: 'Shoper',
        link: '/collect/shoper/create',
        img: 'logotypes/shoper_36.svg',
    },
    {
        type: 'Ecommerce',
        subtype: 'BaseLinker',
        link: '/collect/baselinker/create',
        img: 'logotypes/baselinker_36.svg',
    },
    {
        type: 'Ecommerce',
        subtype: 'Shopify',
        link: '/collect/shopify/create',
        img: 'logotypes/shopify_36.svg',
    },
    {
        type: 'Ecommerce',
        subtype: 'Magento',
        link: '/collect/magento/create',
        img: 'logotypes/magento_36.svg',
    },
    {
        type: 'Ecommerce',
        subtype: 'PrestaShop',
        link: '/collect/prestashop/create',
        img: 'logotypes/prestashop_36.svg',
    },
    {
        type: 'Ecommerce',
        subtype: 'IdoSell',
        link: '/collect/idosell/create',
        img: 'logotypes/idosell.svg',
    },
    {
        type: 'Ecommerce',
        subtype: 'WooCommerce',
        link: '/collect/woocommerce/create',
        img: 'logotypes/woocommerce.svg',
    },
    {
        type: 'Marketplace',
        subtype: 'Allegro',
        link: '/collect/allegro/create',
        img: 'logotypes/allegro.svg',
    },
    {
        type: 'CRM',
        subtype: 'HubSpot',
        link: '/collect/hubspot/create',
        img: 'logotypes/hubspot_36.svg',
    },
    {
        type: 'Other Integrations',
        subtype: 'Custom Data',
        link: '/collect/customData/create',
        img: 'logotypes/cost_36.svg',
    },
    {
        type: 'Other Integrations',
        subtype: 'Leads Ads to Sendgrid',
        link: '/collect/leadAdsToSendgrid/create',
        img: 'logotypes/sendgrid_36.svg',
    },
    {
        type: 'Other Integrations',
        subtype: 'Leads Ads to Getresponse',
        link: '/collect/leadAdsToGetresponse/create',
        img: 'logotypes/getresponse_36.svg',
    },
    {
        type: 'Other Integrations',
        subtype: 'Currency Convert',
        link: '/collect/currencyConverters/create',
        img: 'logotypes/currency_36.svg',
    },
    {
        type: 'Other Integrations',
        subtype: 'Custom Stream',
        link: '/collect/datastream/create',
        img: 'logotypes/doc_36.svg',
    },
]
