export const reportTypes = [
    {
        type: 'Query Jobs',
        subtype: 'Custom Queries',
        link: '/reports/queryjobs/create',
    },
    {
        type: 'Query Jobs',
        subtype: 'Predefined Reports',
        link: '/reports/dataproviders/source',
    },
]
